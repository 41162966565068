import styled from "styled-components";
import theme from "../../theme";

export const Wrapper = styled.article`
	grid-column: 1 / -1;
	/* margin-bottom: 50px; */

	${theme.breakpoints.lg} {
		grid-column: 2 / 6;
	}
`;

export const Title = styled.h1`
	margin-right: 10px;
	font-weight: 900;
`;

export const Subtitle = styled.h4`
	font-weight: normal;
`;

export const TitleWrapper = styled.section`
	margin-bottom: 10px;

	${Title}, ${Subtitle} {
		display: inline-block;
		font-size: 16px;
		line-height: 20px;
		text-transform: uppercase;
	}
`;

export const Description = styled.p`
	font-size: 16px;
	line-height: 20px;
`;

export const Input = styled.textarea`
	color: inherit;
	width: 100%;
	outline: none;
	font-size: 16px;
	line-height: 20px;
	border: none;
	background-color: transparent;
	font-family: ${({ theme }) => theme.font.family.sansSerif};
	font-weight: 300;
	min-height: 100px;
	resize: none;
`;

export const ValidationWrapper = styled.section`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 30px;
`;

export const Indicator = styled.div`
	display: inline-block;
	border-radius: 10px;
	width: 10px;
	height: 10px;
	margin-right: 5px;
	background-color: ${({ $danger, theme }) =>
		$danger ? theme.color.danger : theme.color.success};
`;

export const Sub = styled.div`
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	font-family: ${({ theme }) => theme.font.family.sansSerif};
	margin-bottom: 0;

	${({ $hide }) => $hide && `visibility: hidden`}
`;

export const Colored = styled.span`
	color: ${({ $danger, theme }) =>
		$danger ? theme.color.danger : theme.color.success};
`;
