import { Arrow, DownArrow, Text, Wrapper } from "./styles";

import React from "react";

export const Button = ({
	children: text,
	onClick,
	type,
	className,
	downArrow,
	hideArrow,
	dark,
	animate,
	shadow,
	...props
}) => {
	return (
		<Wrapper
			className={className}
			onClick={onClick}
			type={type}
			$dark={dark}
			$animate={animate}
			$shadow={shadow}
			{...props}
		>
			<Text>{text}</Text>
			{!hideArrow && (
				<Arrow
					style={{ height: "20px !important " }}
					$dark={dark}
					$downArrow={downArrow}
				/>
			)}
		</Wrapper>
	);
};
