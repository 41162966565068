const theme = {
	gutter: 15,
	themes: {
		sunset: ["#F6D365", "#FDA085"],
		light: ["#F5F7FA", "#C3CFE2"],
	},
	gradients: {
		sunset: "linear-gradient(156.26deg, #F6D365 0%, #FDA085 100%);",
		light: "linear-gradient(90deg, #F5F7FA 0%, #C3CFE2 100%);",
	},
	color: {
		black: "#000000",
		dark: "#1D1D1B",
		grey: "#2E2E2E",
		lightgrey: "#e5e5e5",
		white: "#ffffff",
		danger: "#FF0000",
		success: "#2EB100",
	},
	font: {
		family: {
			serif: "adobe-caslon-pro, serif",
			sansSerif: "brandon-grotesque, sans-serif",
		},
	},
	breakpoints: {
		xs: "@media (min-width: 0px)",
		sm: "@media (min-width: 576px)",
		md: "@media (min-width: 768px)",
		lg: "@media (min-width: 992px)",
		xl: "@media (min-width: 1200px)",
	},
};

export default theme;
