import styled from "styled-components";

const outerWidth = 275;

export const Wrapper = styled.div.withConfig({
	shouldForwardProp: (prop) => !["$loaded"].includes(prop),
})`
	will-change: opacity, transform;
	transition: opacity 0.3s, transform 0.3s;

	width: ${outerWidth}px;
	position: relative;

	${({ $loaded }) =>
		!$loaded &&
		`
		opacity: 0;
		transform: scale(0.92);
		`}
`;

export const Content = styled.img.withConfig({
	shouldForwardProp: (prop) =>
		![
			"$dX",
			"$dY",
			"$sWidth",
			"$dWidth",
			"$sHeight",
			"$dHeight",
			"$borderRadius",
		].includes(prop),
})`
	position: absolute;
	z-index: 1;

	${({ $dX, $dY, $sWidth, $dWidth, $sHeight, $dHeight, $borderRadius }) => {
		const percX = ($dX / $dWidth) * 100;
		const percY = ($dY / $dHeight) * 100;

		const scale = outerWidth / $dWidth;

		// const scale = 275 / $dWidth;

		return `
            left: ${$dX * scale}px;
            top: ${$dY * scale}px;
            width: ${$sWidth * scale}px;
			height: ${$sHeight * scale}px;
			border-radius: ${$borderRadius}px;

        `;
	}}
`;

export const Frame = styled.img`
	position: relative;

	z-index: 2;
	width: 100%;
	mix-blend-mode: multiply;
`;
