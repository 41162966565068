import { Route, Router, Routes } from "react-router-dom";

import FontFaceObserver from "fontfaceobserver";
import Intro from "./pages/Intro";
import Preview from "./pages/Preview";
import React from "react";
import ReactGA from "react-ga";
import Results from "./pages/Results";
import Review from "./pages/Review";
import Spinner from "./components/Spinner";
import { ThemeProvider } from "styled-components";
import { createBrowserHistory } from "history";
import fields from "./data/fields";
import theme from "./theme";

const trackingId = "UA-165932948-1";
ReactGA.initialize(trackingId); // initialize ReactGA with trackingId

// const history = createBrowserHistory();
// history.listen((location) => {
// 	ReactGA.set({ page: location.pathname }); // Update the user's current page
// 	ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

export default function App() {
	const [fontsLoaded, setFontsLoaded] = React.useState(false);
	const [results, setResults] = React.useState(fields);
	const [image, setImage] = React.useState(null);
	const [error, setError] = React.useState(null);

	const handleResultsChange = (updatedResults) => setResults(updatedResults);

	// Track page views using ReactGA
	React.useEffect(() => {
		const handlePageTracking = () => {
			const pathname = window.location.pathname;
			ReactGA.set({ page: pathname }); // Update the user's current page
			ReactGA.pageview(pathname); // Record a pageview for the given page
		};

		handlePageTracking(); // Track initial load
		window.addEventListener("popstate", handlePageTracking); // Track navigation
		return () => window.removeEventListener("popstate", handlePageTracking);
	}, []);

	// Load fonts asynchronously
	React.useEffect(() => {
		if (window.hasOwnProperty("localStorage")) {
			const fields = localStorage.getItem("rol-fields");
			if (fields) {
				setResults(JSON.parse(fields));
			}
		}

		(async function () {
			const timeout = 7500;
			const fonts = [
				{ name: "neue-haas-grotesk-display", weight: 400 },
				{ name: "neue-haas-grotesk-display", weight: 500 },
				{ name: "neue-haas-grotesk-display", weight: 600 },
				{ name: "starling", weight: 700, style: "italic" },
			].map(
				({ name, weight, style = "normal" }) =>
					new FontFaceObserver(name, { weight, style })
			);

			for (const font of fonts) {
				await font.load("BES", timeout);
			}

			setFontsLoaded(true);
		})();
	}, []);

	return (
		<ThemeProvider theme={theme}>
			{!fontsLoaded ? (
				<Spinner title="Loading fonts" />
			) : (
				<Routes>
					<Route exact path="/" element={<Intro />} />
					<Route
						path="/results"
						element={
							<Results
								results={results}
								image={image}
								onImageLoaded={setImage}
							/>
						}
					/>
					<Route
						exact
						path="/review"
						element={
							<Review
								results={results}
								onResultsChange={handleResultsChange}
							/>
						}
					/>
					<Route
						path="/preview"
						element={<Preview image={image} />}
					/>
				</Routes>
			)}
		</ThemeProvider>
	);
}
