import styled from "styled-components";
import theme from "../../theme";

export const Color = styled.main`
	width: 100%;
	min-height: 100vh;

	${({ $dark }) =>
		$dark &&
		`
		color: white;
		background-color: ${theme.color.dark};
	`}
`;

export const Wrapper = styled.div`
	width: 100%;
	min-height: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: center;
`;
