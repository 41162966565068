export default [
	{
		title: "Lockscreen",
		icon: require("../assets/layout-icons/lockscreen.svg"),
	},
	{
		title: "Sharable",
		icon: require("../assets/layout-icons/sharable.svg"),
	},
	{
		title: "Both",
		icon: require("../assets/layout-icons/both.svg"),
	},
];
