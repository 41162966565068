import Canvas from "./canvas";

const format = {
	offsetTop: 0,

	renderGradient: async (canvas, theme) => {
		await canvas.gradient(theme);
	},

	renderImage: async (canvas, src) => {
		await canvas.image(
			src,
			0,
			0,
			canvas.canvasElement.width / canvas.scale,
			canvas.canvasElement.height / canvas.scale,
			{ blendMode: "multiply" }
		);
	},

	renderLogo: async (canvas, screenHeight) => {
		const canvasHeight = canvas.canvasElement.height / canvas.scale;
		format.offsetTop = canvasHeight < 670 ? 40 : 150;

		const width = 58;
		const height = 58;

		const image = require("../assets/CLG_White_notext.png");

		await canvas.image(
			image,
			canvas.canvasElement.width / (canvas.scale * 2) - width / 2,
			format.offsetTop,
			width,
			height
			// { blendMode: "multiply" }
		);

		format.offsetTop += 31;
	},

	renderFooter: (canvas) => {
		canvas.canvasElement.getContext("2d").fillStyle = "#B9B9B9";

		canvas.text("Pursuing maturity in Christ", {
			x: canvas.canvasElement.width / canvas.scale / 2,
			y: canvas.canvasElement.height / canvas.scale - 80,
			fontFamily: "brandon-grotesque, sans-serif",
			fontWeight: 700,
			fontSize: 14,
			lineHeight: 14,
			textAlign: "center",
		});
	},

	renderPractices: (canvas, practices, screenHeight) => {
		const { canvasElement } = canvas,
			context = canvasElement.getContext("2d");
		const canvasHeight = canvasElement.height / canvas.scale;

		context.textAlign = "center";

		// const totalHeight = 112 * (practices.length + 1);

		// format.offsetTop +=
		// 	screenHeight / 2 - totalHeight / 2 - (screenHeight > 670 ? 20 : 10);

		const totalHeight = 112 * practices.length; // Each practice takes up 112px
		const footerOffset = 100; // Reserve space for footer (recommended offset)

		// Start from the bottom and subtract the total height of practices and footer
		format.offsetTop = canvasHeight - totalHeight - footerOffset;

		practices.forEach(({ title, color, value }) => {
			format.offsetTop += 6;

			context.fillStyle = color;

			// '8202' = smaller space

			// Label
			canvas.text(
				title.toUpperCase().split("").join(String.fromCharCode(8202)),
				{
					x: canvasElement.width / canvas.scale / 2,
					y: format.offsetTop,
					fontFamily: "brandon-grotesque, sans-serif",
					fontWeight: 700,
					fontSize: 14,
					lineHeight: 20,
				}
			);

			format.offsetTop += 28;

			// Value
			canvas.text(value, {
				x: canvasElement.width / canvas.scale / 2,
				y: format.offsetTop,
				maxWidth: 192,
				fontFamily: "adobe-caslon-pro, serif",
				fontWeight: 500,
				fontSize: 18,
				lineHeight: 22,
			});

			format.offsetTop += 78;
		});
	},

	toImage: async ({ practices, layout, color, constraints }) => {
		const { sWidth, sHeight } = constraints;

		const canvas = new Canvas().init(sWidth, sHeight);

		format.offsetTop = sHeight > 670 ? 30 : 0;

		const { canvasElement } = canvas,
			context = canvasElement.getContext("2d");

		context.fillStyle = color;
		context.fillRect(0, 0, canvasElement.width, canvasElement.height);

		await format.renderLogo(canvas);

		format.renderPractices(canvas, practices, sHeight);

		format.renderFooter(canvas);

		return canvas.canvasElement.toDataURL("image/png", 1);
	},
};

export default format;
