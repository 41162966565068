import { Wrapper as DefaultWrapper } from "../../components/styles";
import styled from "styled-components";
import theme from "../../theme";

export const Wrapper = styled(DefaultWrapper)`
	max-height: 0;
`;

export const TextWrapper = styled.div`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;

	${theme.breakpoints.lg} {
		width: 465px;
		grid-column-start: 3;
		grid-column-end: 9;
	}
`;

export const Title = styled.h1`
	font-family: ${theme.font.family.serif};
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	line-height: 42px;
	margin-bottom: 45px;

	${theme.breakpoints.lg} {
		font-size: 56px;
		line-height: 64px;
	}
`;
