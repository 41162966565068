const fields = [
	{
		title: "God’s Presence",
		subtitle: "God’s Presence",
		description:
			"Where the world aims to keep us constantly distracted, addicted to entertainment and so busy we are oblivious to Jesus’ leading, we are seeking what Jesus actually offers us: his constant presence, intimate relationship and a life guided by his voice through immersion in prayer, worship and the scriptures. What could be a key practice to engage in to become a person marked out by God’s presence?",
		placeholder: "e.g. 15 mins devotional each morning",
		value: "",
		color: "#DBB0A5",
	},
	{
		title: "Loving Community",
		subtitle: "Loving Community",
		description:
			"Where the world would seek to have us focus in on ourselves – prioritising our needs and wants – we are seeking to live as Jesus called us to: in community. Our hope is that the world would tell we are his disciples by the way we love one another; to reject a “life turned in on itself” and embrace Jesus' sacrificial way of love that was – to the point of death – “for others”. What could be a key practice to engage in to become a person living out loving community?",
		placeholder:
			"I.e. pray about those in my life and act to encourage them",
		value: "",
		color: "#C4A62A",
	},
	{
		title: "Deep Formation",
		subtitle: "Deep Formation",
		description:
			"Where the world conspires to keep us proud, projecting the right image and content with surface-level religion – and therefore perpetually spiritually immature – we are seeking to allow Jesus to transform our hearts at the deepest level that we might mature in our faith and formation; live holy lives that honour the name of Jesus; and live in the freedom Christ set us free for. What is an area of your life where you recognise the need for deep transformation and what practice might help you step towards it?",
		placeholder: "I.e. practice the Examen for ten minutes before bed",
		value: "",
		color: "#345A6E",
	},
	{
		title: "Imaginative Mission",
		subtitle: "Imaginative Mission",
		description:
			"Where the world seduces us with comfort and disarms us with apathy, we are looking to live obedient, fruitful lives of bold mission, courageous faith and kinship with the oppressed and marginalised. This flows from a radical commitment to live with prophetic imagination and see the world as it could be and not just as it is. How could you practice living more boldly on mission?",
		placeholder: "I.e. practice the Examen for ten minutes before bed",
		value: "",
		color: "#798D5A",
	},
];
export default fields;
