import {
	Colored,
	Description,
	Indicator,
	Input,
	Sub,
	Subtitle,
	Title,
	TitleWrapper,
	ValidationWrapper,
	Wrapper,
} from "./styles";

import React from "react";
import { isValidField } from "../../functions";

export const Field = React.forwardRef(
	({ title, subtitle, description, color, value, onChange }, ref) => {
		const invalid = !isValidField(value);
		const [focused, setFocused] = React.useState(false);
		const [visited, setVisited] = React.useState(false);

		const handleFocus = () => setFocused(true);
		const handleBlur = () => {
			if (!visited) setVisited(true);
			setFocused(false);
		};
		const handleChange = ({ target }) => {
			if (!visited) setVisited(true);
			onChange(target.value);
		};

		return (
			<Wrapper>
				<TitleWrapper style={{ color }}>
					<Title>{title}</Title>
					{/* <Subtitle>{subtitle}</Subtitle> */}
				</TitleWrapper>

				<Description>{description}</Description>
				<Input
					ref={ref}
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={value}
					onChange={handleChange}
					placeholder="Type your answer here..."
				/>
				<ValidationWrapper>
					<Sub $hide={!focused}>
						<Indicator $danger={visited && invalid} />
						<Colored $danger={visited && invalid}>
							{value.length}
						</Colored>
						/60 characters
					</Sub>
					{invalid && visited && (
						<Sub>
							<Colored danger>
								Please make sure this field is filled out
								correctly
							</Colored>
						</Sub>
					)}
				</ValidationWrapper>
			</Wrapper>
		);
	}
);
