import { Logo, LogoWithText, Subtitle, Wrapper } from "../../components/styles";
import { TextWrapper, Title } from "./styles";

import Background from "../../components/Background";
import Button from "../../components/Button";
import React from "react";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

export const Intro = () => {
	const navigate = useNavigate();

	const handleBegin = () => {
		navigate("/review");
	};

	return (
		<Background $dark>
			<Wrapper>
				<LogoWithText />
				<TextWrapper>
					<Title>Pursuing maturity in Christ</Title>
					<Button animate onClick={handleBegin}>
						Begin
					</Button>
				</TextWrapper>
			</Wrapper>
		</Background>
	);
};
