import {
	Background,
	DeviceWrapper,
	DownloadButtonWrapper,
	TextWrapper,
	Wrapper,
} from "./styles";
import {
	Heading,
	LogoWithTextinBlack,
	Subheading,
	Subtitle,
	Tag,
} from "../../components/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { isCompletedResults, isValidResults } from "../../functions";

import Button from "../../components/Button";
import DeviceChooser from "../../components/DeviceChooser";
import LayoutChooser from "../../components/LayoutChooser";
import Mockup from "../../components/Mockup";
import React from "react";
import Spinner from "../../components/Spinner";
import ThemeChooser from "../../components/ThemeChooser";
import devices from "../../data/devices";
import format from "../../scripts/format";
import layouts from "../../data/layouts";
import themes from "../../data/themes";

export const Results = ({ results, onImageLoaded, image }) => {
	const navigate = useNavigate();

	const [selectedDevice, setSelectedDevice] = React.useState(1);
	const [selectedTheme, setSelectedTheme] = React.useState(0);
	const [selectedLayout, setSelectedLayout] = React.useState(1);
	// const [secondaryImage, setSecondaryImage] = React.useState();

	const [loaded, setLoaded] = React.useState(false);

	React.useEffect(() => {
		setLoaded(false);
	}, [selectedTheme, selectedDevice, selectedLayout]);

	React.useEffect(() => {
		if (!loaded) {
			// Allow loading wheel to load
			window.setTimeout(() => {
				(async function () {
					let uri = await format.toImage({
						practices: results,
						layout: layouts[selectedLayout],
						color: themes[selectedTheme].color,
						fgDark: themes[selectedTheme].hasOwnProperty("dark"),
						constraints: devices[selectedDevice].constraints,
					});

					onImageLoaded(uri);
				})();
			}, 500);
		}
	}, [loaded]);

	const handleDownload = () => {
		navigate("/preview");
	};

	if (!isValidResults(results) || !isCompletedResults(results)) {
		return <Navigate path="/review" replace />;
	} else {
		return image ? (
			<Background>
				<Wrapper style={{ color: "black" }}>
					<LogoWithTextinBlack />
					<TextWrapper>
						<Heading>Great news!</Heading>
						<Subheading>
							Your Common Life rhythms are ready to download.
							Choose the right…
						</Subheading>
					</TextWrapper>
					<DeviceWrapper>
						{!loaded && <Spinner style={{ height: "600px" }} />}
						<Mockup
							loaded={loaded}
							device={devices[selectedDevice]}
							content={image}
							onLoad={() => setLoaded(true)}
						/>
					</DeviceWrapper>
					<TextWrapper>
						<Subtitle>Choose your device</Subtitle>

						<DeviceChooser
							onChange={(index) => setSelectedDevice(index)}
							selected={selectedDevice}
						/>
					</TextWrapper>

					{/*<TextWrapper>
						<Subtitle>Choose your theme</Subtitle>

						<ThemeChooser
							onChange={(index) => setSelectedTheme(index)}
							selected={selectedTheme}
						/>
					</TextWrapper>
					<TextWrapper>
						<Subtitle>Choose your image</Subtitle>

						<LayoutChooser
							onChange={(index) => setSelectedLayout(index)}
							selected={selectedLayout}
						/>
					</TextWrapper> */}
					<TextWrapper>
						<DownloadButtonWrapper>
							<span className="only-mobile">
								<Button
									hideArrow
									shadow
									$dark
									onClick={handleDownload}
								>
									Download ↓
								</Button>
							</span>
							<a
								className="only-desktop"
								download={`RuleOfLife_${layouts[selectedLayout].title}.png`}
								href={image}
							>
								<Button
									hideArrow
									shadow
									$dark
									onClick={handleDownload}
								>
									Download ↓
								</Button>
							</a>
						</DownloadButtonWrapper>
						<Tag>
							We pray these rhythms help you intentionally pursue
							maturity in Christ
						</Tag>
					</TextWrapper>
				</Wrapper>
			</Background>
		) : (
			<Spinner title="Loading resources" />
		);
	}
};
