import styled from "styled-components";
import theme from "../../theme";

export const ButtonWrapper = styled.div`
	grid-column: 1 / -1;
	text-align: center;
	margin-bottom: 30px;

	${theme.breakpoints.lg} {
		text-align: left;
		grid-column: 2 / 6;
	}
`;
