import { Content, Frame, Wrapper } from "./styles";

import React from "react";

export const Mockup = ({
	loaded,
	onLoad,
	device,
	content,
	image,
	...props
}) => {
	const {
		constraints: { dX, dY, sWidth, dWidth, sHeight, dHeight, borderRadius },
		mockup,
	} = device;

	return (
		<Wrapper $loaded={loaded} {...props}>
			<Frame src={mockup} />
			<Content
				onLoad={onLoad}
				$dX={dX}
				$dY={dY}
				$sWidth={sWidth}
				$dWidth={dWidth}
				$sHeight={sHeight}
				$dHeight={dHeight}
				$borderRadius={borderRadius}
				src={content}
			/>
		</Wrapper>
	);
};
