import { Color, Wrapper } from "./styles";

import React from "react";

export const Background = ({ children, ...props }) => {
	return (
		<Color {...props}>
			<Wrapper>{children}</Wrapper>
		</Color>
	);
};
