import { Logo, LogoWithText, Wrapper } from "../../components/styles";
import {
	getPractices,
	isCompletedResults,
	isValidField,
	isValidResults,
	setField,
} from "../../functions";

import Background from "../../components/Background";
import Button from "../../components/Button";
import { ButtonWrapper } from "./styles";
import Field from "../../components/Field";
import React from "react";
import { Navigate } from "react-router-dom";
import animateScrollTo from "animated-scroll-to";
import { useNavigate } from "react-router-dom";

export const Review = ({ results = [], onResultsChange }) => {
	const navigate = useNavigate();
	const [fieldRefs, setFieldRefs] = React.useState([]);

	const addFieldRef = (ref) => {
		if (ref && !fieldRefs.includes(ref)) {
			let refs = [...fieldRefs];
			refs.unshift(ref);
			setFieldRefs(refs);
		}
	};

	const saveFieldsToLocalStorage = (fields) => {
		if (window.localStorage) {
			localStorage.setItem("rol-fields", JSON.stringify(fields));
		}
	};

	const handleSubmit = async () => {
		if (isValidResults(results)) {
			if (isCompletedResults(results)) {
				saveFieldsToLocalStorage(results);
				navigate("/results");
			} else {
				const field = fieldRefs.find(
					({ value }) => !isValidField(value)
				);
				await animateScrollTo(field.previousSibling);
				field.focus();
			}
		}
	};

	const handleFieldChange = ({ title, value }) => {
		const lastWord = title.split(" ").slice(-1)[0];

		const copyOfResults = [...results];
		const index = results.findIndex((field) => field.title === title);
		copyOfResults[index].title = lastWord;
		copyOfResults[index].value = value;

		onResultsChange(copyOfResults);
	};

	return isValidResults(results) ? (
		<Background $dark>
			<Wrapper>
				<LogoWithText />
				{results.map(
					({ value, title, color, subtitle, description }, index) => (
						<Field
							onChange={(value) =>
								handleFieldChange({ title, value })
							}
							color={color}
							value={value}
							title={subtitle}
							subtitle={subtitle}
							description={description}
							key={`review-field-${index}`}
							ref={(ref) => addFieldRef(ref)}
						/>
					)
				)}
				<ButtonWrapper>
					<Button animate onClick={handleSubmit}>
						Create Rule of Life
					</Button>
				</ButtonWrapper>
			</Wrapper>
		</Background>
	) : (
		<Navigate path="/" replace />
	);
};
