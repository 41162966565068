import { Logo, Wrapper } from "./styles";

import React from "react";

export const Spinner = ({ title, ...props }) => {
	return (
		<Wrapper {...props}>
			<Logo />
		</Wrapper>
	);
};
