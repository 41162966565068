import arrow from "../../assets/arrow.svg";
import arrowBlack from "../../assets/arrow-black.svg";
import styled from "styled-components";
import theme from "../../theme";

export const Text = styled.span`
	text-transform: uppercase;
	font-weight: 700;
	will-change: margin-right;
	transition: margin-right 0.3s;
	color: inherit;
	font-size: 16px;
	line-height: 30px;
	vertical-align: middle;
	letter-spacing: 1.2px;
`;

export const Arrow = styled.div`
	margin-left: 12px;
	background-image: url("${arrow}");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;

	${({ $downArrow }) =>
		$downArrow
			? `
			transform-origin: 6px 10px;
			transform: rotate(90deg);`
			: `height: 12px;`}
`;

export const Wrapper = styled.button.withConfig({
	shouldForwardProp: (prop) =>
		!["$dark", "$animate", "$shadow"].includes(prop),
})`
	color: inherit;
	border: none;
	appearance: none;
	background-color: transparent;
	transition: border-color 0.3s;
	will-change: border-color;
	outline: none;
	padding: 0;
	margin-bottom: ${theme.gutter}px;

	${({ $shadow }) =>
		$shadow &&
		`
		padding: 4px 10px;
		text-transform: uppercase;
		box-sizing: border-box;
		box-shadow: 0 0px 3px #00000028;
		border: 1px solid #e5e5e5;

		span {
			font-size: 12px;
		}

		${Arrow} {
			transform-origin: center 75%;
			transform: rotate(90deg) scale(0.75);
		}
	`};

	&:hover {
		${Text} {
			${({ $animate }) => $animate && `margin-right: 20px;`}
		}
	}
`;
