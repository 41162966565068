import styled, { keyframes } from "styled-components";

const blink = keyframes`
	from {
		opacity: 0;
		transform: scale(1);
	}
	15% {
		transform: scale(1.2)
	}
	50% {
		opacity: 1;
		transform: scale(1);
	}
	to {
		opacity: 0;
		transform: scale(1);
	}
`;

export const Logo = styled.img.attrs({
	src: require("../../assets/CLG_Black_notext.png"),
})`
	width: 100px;
	height: 100px;
	display: inline-block;
	animation: ${blink} 1s infinite;
`;

export const Wrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #fdfdfd;
	z-index: 1000;
`;
