import pixel9ProIcon from "../assets/device-icons/android.svg";
import pixel9ProMockup from "../assets/mockups/pixel-9-pro.svg";
import iphone8Icon from "../assets/device-icons/iphone-8.svg";
import iphone8Mockup from "../assets/mockups/iphone-8.svg";
import iphone11ProIcon from "../assets/device-icons/iphone-11-pro.svg";
import iphone11ProMockup from "../assets/mockups/iphone-11-pro.svg";
import iphone13ProIcon from "../assets/device-icons/iphone-13-pro.svg";
import iphone13ProMockup from "../assets/mockups/iphone-13-pro.svg";

import iphone16ProIcon from "../assets/device-icons/iphone-16-pro.svg";
import iphone16ProMockup from "../assets/mockups/iphone-16-Pro.svg";

export default [
	{
		title: "pixel-9-pro",
		icon: pixel9ProIcon,
		mockup: pixel9ProMockup,
		constraints: {
			dWidth: 430,
			dHeight: 907,
			sWidth: 410,
			sHeight: 890,
			dX: 8,
			dY: 10,
			borderRadius: 28,
		},
	},
	{
		title: "iphone-8",
		icon: iphone8Icon,
		mockup: iphone8Mockup,
		constraints: {
			dWidth: 427,
			dHeight: 858,
			sWidth: 375,
			sHeight: 667,
			dX: 26.3,
			dY: 94.8,
			borderRadius: 0,
		},
	},
	{
		title: "iphone-11-pro",
		icon: iphone11ProIcon,
		mockup: iphone11ProMockup,
		constraints: {
			dWidth: 427,
			dHeight: 858,
			sWidth: 375,
			sHeight: 812,
			dX: 26,
			dY: 23,
			borderRadius: 24,
		},
	},
	{
		title: "iphone-13-pro",
		icon: iphone13ProIcon,
		mockup: iphone13ProMockup,
		constraints: {
			dWidth: 437,
			dHeight: 884,
			sWidth: 390,
			sHeight: 844,
			dX: 23.8,
			dY: 20,
			borderRadius: 28,
		},
	},
	{
		title: "iphone-16-pro",
		icon: iphone16ProIcon,
		mockup: iphone16ProMockup,
		constraints: {
			dWidth: 417,
			dHeight: 876,
			sWidth: 393,
			sHeight: 852,
			dX: 12,
			dY: 12,
			borderRadius: 38,
		},
	},
];
