import { Selectable } from "../styles";
import devices from "../../data/devices";
import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-around;
	width: 100%;
	// column-gap: 20px;
`;

// Got a width issues

export const Item = styled(Selectable)`
	flex: 0 0 33.3333%;
	width: 93px;
	height: 135px;
	margin: 0 auto;
	background-image: url("${({ $image }) => $image}");
	background-size: 85px 127px;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 3px;

	&:nth-child(4),
	&:nth-child(5) {
		width: calc(50% - 10px);
		margin: 0;
	}
`;
